<template>
  <rk-dropdown-menu-item
    v-if="!item.divider && !item.group"
    :key="item.label"
    active-class="bg-primary-50 text-primary"
    :as="item.href ? 'a' : item.to ? NuxtLink : 'button'"
    class="data-[highlighted]:!bg-primary-50/70 overflow-hidden !outline-0 font-semibold flex text-left items-start p-2 gap-4 min-w-full rounded transition active:scale-95"
    :class="{
      'text-primary font-semibold bg-primary-50': item.selected,
    }"
    :data-cy-menu-item="item.id"
    :href="item.href"
    :target="item.href ? '_blank' : '_self'"
    :to="item.to"
    v-on="item.listeners ?? {}"
  >
    <div v-if="item.icon || $slots[`prepend_${item.id}`]" class="w-5 h-5">
      <app-icon
        v-if="item.icon"
        class="text-subtle transition"
        :icon="item.icon"
        :size="20"
      />
    </div>

    <div class="overflow-hidden">
      <p class="whitespace-nowrap overflow-hidden text-ellipsis">
        {{ item.label }}
      </p>
      <p
        v-if="item.description"
        class="text-subtle text-xs font-normal transition"
      >
        {{ item.description }}
      </p>
    </div>

    <div
      v-if="item.appendIcon || $slots[`append_${item.id}`]"
      class="flex-auto flex justify-end"
    >
      <slot :name="`append_${item.id}`">
        <app-icon v-if="item.appendIcon" :icon="item.appendIcon" :size="16" />
      </slot>
    </div>
  </rk-dropdown-menu-item>
</template>

<script lang="ts" setup>
import type { DropdownMenuItemDefinition } from "../dropdown.model";

defineProps<{
  item: DropdownMenuItemDefinition;
}>();

const NuxtLink = resolveComponent("nuxt-link");
</script>
